import React from "react";

const QuizQuestion = ({ title, disabled, onQuestionClick }) => {
  return (
    <button
      onClick={onQuestionClick}
      disabled={disabled}
      // text-app-title
      className="cursor-pointer bg-gray-300 min-h-[5rem] p-4 col-span-3 md:col-span-1 flex items-center justify-center font-bold text-lg md:text-lg shadow-md"
      // style={{ backgroundColor: '#B6BEC3' }}
    >
      {title}
    </button>
  );
};

export default QuizQuestion;
