import { navigate } from "gatsby";
import React from "react";
import { useCallback, useEffect, useState } from "react";
import httpClient from "../services/httpClient";
import AppPage from "../components/AppPage";
import QuizQuestion from "../components/quiz/QuizQuestion";
import QuizHeader from "../components/quiz/QuizHeader";
import {useSelector} from "react-redux";
import axios from "axios";

const ModuleQuizComponent= ({ location }) => {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [score, setScore] = useState(0);
  const [isFinished, setIsFinished] = useState(false);
  const [disable, setDisable] = useState(false);
  const module = location.state ? location.state : null;
  const questions = module?.questions;
  const moduleId = module?.moduleId;
  const userEmail = useSelector((state) => state.auth.email);

  const progressWidth = {
    width: !isFinished ? ((currentQuestion + 1) * 100) / 3 + "%" : 0,
  };

  const finalScore = parseInt((score * 100) / 3);

  const sendGrade = useCallback(async () => {
    const grade = finalScore / 10;
    console.log(grade)
    const approved = grade > 7 ? true : false;
    console.log('Final',grade, moduleId, approved)
    await httpClient(
      "/module_grades",
      {
        module_id: moduleId,
        grade,
        approved,
      },
      "post"
    );

    if(moduleId >= 7 && approved) {
      try {
        const {data} = await axios.post(
            `https://centralizedlogin.rplusrprogram.com/ses/sendEmail`,
            {
              customer_email: userEmail,
            }
        );
        console.log('data: ', data);
      } catch (err) {
        console.log('err: ', err);
      }
    }

  }, [finalScore, moduleId]);

  useEffect(() => {
    if (!module) {
      navigate("/main/", {
        replace: true,
      });
    }
    if (isFinished) {
      sendGrade();
    }
  }, [module, isFinished, sendGrade]);

  const handleAnswerSubmit = (isCorrect, e) => {
    setDisable(true);
    // text-app-title
    e.target.classList.remove("text-gray-500", "bg-gray-300");
    if (isCorrect === true) {
      e.target.classList.add("text-white", "bg-app-success");
      setScore(score + 1);
    } else {
      e.target.classList.add("text-white", "bg-red-400");
    }
    setTimeout(() => {
      if (currentQuestion === questions.length - 1) {
        setIsFinished(true);
      } else {
        setCurrentQuestion(currentQuestion + 1);
      }
      setDisable(false);
    }, 2000);
  };

  const handleBackClick = () => {
    navigate("/main/", {
      replace: true,
    });
  };

  const handleRetryClick = () => {
    setCurrentQuestion(0);
    setScore(0);
    setIsFinished(false);
  };

  const questionsSection = module ? (
    <div className="grid grid-cols-3 gap-3">
      {questions[currentQuestion].choices.map((choice) => (
        <QuizQuestion
          onQuestionClick={(e) => handleAnswerSubmit(choice.isCorrect, e)}
          title={choice.answer}
          key={choice.answer}
          disabled={disable}
        />
      ))}
    </div>
  ) : null;

  const failedQuiz = module ? (
    <div>
      <div className="text-3xl font-bold text-black my-14 text-center">
        Keep on trying, we believe in you! Try scoring above 70% to move forward
      </div>
      <div className="flex justify-between">
        <button
          className="text-white h-full px-5 py-1  bg-app-title"
          onClick={handleRetryClick}
        >
          RETRY QUIZ
        </button>
        <button
          className="text-white h-full px-5 py-1 bg-app-button-light"
          onClick={handleBackClick}
        >
          GO BACK
        </button>
      </div>
    </div>
  ) : null;

  const passedQuiz = (
    <div>
      <div className="text-3xl font-bold text-black my-14 text-center">
        Great Job! You passed this module's quiz. Move forward to the next
        module:
      </div>
      <div className="flex justify-center">
        <button
          className="text-white h-full px-5 py-1  bg-app-success"
          onClick={handleBackClick}
        >
          NEXT
        </button>
      </div>
    </div>
  );

  const completedQuiz = (
      <div>
        <div className="text-3xl font-bold text-black my-14 text-center">
          Congratulations you just completed your certification! Please check your email.
        </div>
        <div className="flex justify-center">
          <button
              className="text-white h-full px-5 py-1  bg-app-success"
              onClick={handleBackClick}
          >
            NEXT
          </button>
        </div>
      </div>
  );

  return (
      <div
        className="font-sans relative flex justify-center items-center bg-cover bg-center min-h-screen"
        style={{
          backgroundImage: `url(${module?.image.url})`,
        }}
      >
        <QuizHeader />
        <div
          className="
            justify-center text-center
            w-4/5 sm:w-4/6 md:w-3/4
            lg:w-3/5 xl:w-1/2 mt-[10rem]
            mb-[5rem] md:mt-[5rem] md:mb-0
            py-5 px-5 shadow-outline
            "
            style={{ backgroundColor: '#ffffff', borderRadius: '5px' }}
        >
          <p className="text-3xl font-bold text-black mb-3">
            {isFinished ? "Score" : "Quiz"}
          </p>
          <div className="h-3 bg-gray-500">
            <div className="h-full bg-gray-300" style={progressWidth}></div>
          </div>
          {isFinished ? (
            <p className="text-5xl md:text-6xl font-bold text-black my-14">
              {finalScore + "%"}
            </p>
          ) : module ? (
            <p className="text-2xl font-bold text-black my-10">
              {questions[currentQuestion].title}
            </p>
          ) : null}
          {isFinished
            ? finalScore === 100
              ? moduleId >= 7 ? completedQuiz : passedQuiz
              : failedQuiz
            : questionsSection}
        </div>
      </div>
  );
};


const ModuleQuiz = (props) => {
    return <AppPage><ModuleQuizComponent {...props}/></AppPage>
}

export default ModuleQuiz;
